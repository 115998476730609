// src/App.js
import React, { useEffect } from "react";
import { Stack, Image, Badge } from "@mantine/core";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { startFirebaseUI } from "./FirebaseUIAuth";
import Dashboard from "./components/DashBoard";
import HeartHaven from "./modules/HeartHaven/HeartHaven";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  const user = useAuth();

  useEffect(() => {
    if (!user) {
      startFirebaseUI("#firebaseui-auth-container");
    }
  }, [user]);

  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // This assumes the body of your page is at least 100% of the viewport height
  };

  console.log("User:", user);

  return (
    <AuthProvider>
      <Router>
        {user ? (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/hearthaven" element={<HeartHaven />} />
            {/* Add other module routes here */}
          </Routes>
        ) : (
          <Stack align="center" style={centerStyle}>
            <Image h={300} w="auto" fit="contain" src="/logo512.png" />
            <Badge
              size="30px"
              variant="gradient"
              gradient={{ from: "blue", to: "cyan", deg: 196 }}
            >
              Zenix
            </Badge>
            <div id="firebaseui-auth-container"></div>
          </Stack>
        )}
      </Router>
    </AuthProvider>
  );
};

export default App;
