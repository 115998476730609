import React, {useState} from 'react';
import { useMantineTheme, SegmentedControl } from '@mantine/core';
import { useTheme } from '../context/ThemeContext'; // Make sure the path to useTheme hook is correct

const ashImage = '/img/Ash.png';
const risingImage = '/img/Rising.png';
const flamingImage = '/img/Flaming.png';




const StateSlider = () => {
  const {updateTheme } = useTheme(); // Assuming `themeName` tells you which theme is active
  // eslint-disable-next-line no-unused-vars
  const mantineTheme = useMantineTheme();
  const [value, setValue] = useState('Rising');

  const handleStateChange = (value) => {
    setValue(value);
    updateTheme(value); // This assumes that your theme names directly correspond to the segment values
  };






  return (
    <div style={{ width: "90%", margin: '20px', height: "100px" }}> {/* Center the slider */}
      <SegmentedControl
          value={value}
          onChange={handleStateChange}
          fullWidth
          size="xl"
          radius="xl"
          transitionDuration={500}
          transitionTimingFunction="linear"
          data={[
            {
              // Modified label structure
              label: (
                <div style={{ textAlign: "center", color: "black" }}>
                  <img src={ashImage} alt="Ash" style={{ width: "40px" }} />
                  <br /> Ash
                </div>
              ),
              value: "Ash",
            },
            {
              label: (
                <div style={{ textAlign: "center", color: "black" }}>
                  <img
                    src={risingImage}
                    alt="Rising"
                    style={{ width: "40px" }}
                  />
                  <br /> Rising
                </div>
              ),
              value: "Rising",
            },
            {
              label: (
                <div style={{ textAlign: "center", color: "black" }}>
                  <img
                    src={flamingImage}
                    alt="Flaming"
                    style={{ width: "40px" }}
                  />
                  <br /> Flaming
                </div>
              ),
              value: "Flaming",
            },
          ]}
        />

      
      <div ></div>
    </div>
  );
};

export default StateSlider;
