import { firebase } from './firebase';
import * as firebaseui from 'firebaseui';

const uiConfig = {
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // Additional providers can be listed here...
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      // Log successful authentication
      console.log("Authentication successful, user:", authResult.user);
      console.log("Additional user info:", authResult.additionalUserInfo);
      console.log("Redirect URL:", redirectUrl);

      // Return false to prevent automatic redirection after sign-in
      return true;
    },
    // Optionally, handle UI changes or errors during the sign-in process
    uiShown: () => {
      console.log("FirebaseUI widget is rendered and visible.");
    }
  },
  signInFlow: 'popup',

  // Other config options...
};

// Initialize the FirebaseUI Auth UI component
const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

export const startFirebaseUI = (elementId) => {
  console.log("Starting FirebaseUI at element:", elementId);
  
  try {
    ui.start(elementId, uiConfig);
  } catch (error) {
    console.error("Failed to start FirebaseUI:", error);
  }
};
