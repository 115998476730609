import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { AwesomeButton } from 'react-awesome-button';
import { IconDoorExit } from '@tabler/icons-react';




const LogoutButton = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(true); // Example initial state

    const handleLogout = async () => {
        try {
            await firebase.auth().signOut();
            setIsLoggedIn(false); // Update login state
            console.log('Logged out successfully');
        } catch (error) {
            console.error('Logout Error:', error.message);
        }
    };

    return (
        isLoggedIn && ( // Conditionally render button only if logged in
        
        <AwesomeButton type="danger" size="icon" onPress={handleLogout}><IconDoorExit /></AwesomeButton>
    )
    );
};

export default LogoutButton;
