import React from 'react';
import { AwesomeButton } from 'react-awesome-button';
import {IconArrowLeft} from '@tabler/icons-react'; // Use an appropriate icon for the back button
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  // Function to handle the back navigation
  const handleBack = () => {
    navigate(-1); // Navigate to the previous page in history
  };

  return (
    <AwesomeButton
      type="secondary"
      size="icon"
      onPress={handleBack}
    >
      <IconArrowLeft /> {/* Icon showing the left arrow */}
    </AwesomeButton>
  );
};

export default BackButton;
