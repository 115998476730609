import { db } from '../firebase'; // Ensure this path is correct
import { collection,query, where, addDoc, getDoc, doc, updateDoc, deleteDoc, getDocs, limit } from 'firebase/firestore';

// Add a TableTopic
export const addTabletopic = async (TableTopicData) => {
  try {
    const docRef = await addDoc(collection(db, 'TableTopics'), TableTopicData);
    console.log("Tabletopic written with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding TableTopic: ", error);
    throw error;
  }
};

// Get a single TableTopic
export const getTabletopic = async (id) => {
  try {
    const docRef = doc(db, 'TableTopics', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      throw new Error('Tabletopic not found');
    }
  } catch (error) {
    console.error("Error getting TableTopic: ", error);
    throw error;
  }
};

// Get all TableTopics
export const getAllTabletopics = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'TableTopics'));
    const TableTopics = [];
    querySnapshot.forEach((doc) => {
      TableTopics.push({ id: doc.id, ...doc.data() });
    });
    return TableTopics;
  } catch (error) {
    console.error("Error getting documents: ", error);
    throw error;
  }
};

// Update a TableTopic
export const updateTabletopic = async (id, updateData) => {
  try {
    const docRef = doc(db, 'TableTopics', id);
    await updateDoc(docRef, updateData);
    console.log("Tabletopic updated with ID: ", id);
  } catch (error) {
    console.error("Error updating TableTopic: ", error);
    throw error;
  }
};

// Delete a TableTopic
export const deleteTabletopic = async (id) => {
  try {
    const docRef = doc(db, 'TableTopics', id);
    await deleteDoc(docRef);
    console.log("Tabletopic deleted with ID: ", id);
  } catch (error) {
    console.error("Error deleting TableTopic: ", error);
    throw error;
  }
};


export async function fetchQuestions() {
  try {
    const q = query(collection(db, "TableTopics"), where("seen", "==", "FALSE"), where("hide", "==", "FALSE"), limit(10));
    const querySnapshot = await getDocs(q);
    const questions = [];
    querySnapshot.forEach((doc) => {
      questions.push({ id: doc.id, ...doc.data() });
      console.log(doc.id, " => ", doc.data());
    });
    console.log("Fetched questions:", questions);
    return questions;
  } catch (error) {
    console.error("Error fetching questions from Firestore:", error);
    // Handle the error appropriately
    // You might want to throw the error or return a default value
    throw error; // Re-throw the error if you want calling functions to handle it
    // Or return an empty array or null as an indication of the failure
    // return [];
  }
}

export async function updateQuestion(id, updates) {
  const questionRef = doc(db, "TableTopics", id);
  await updateDoc(questionRef, updates);
}