// src/utils/themeConfigurations.js
export const themeConfigs = {
  Ash: {
    gradient: "linear-gradient(to right, rgba(168, 224, 99, 0.3), rgba(86, 178, 47, 0.3))",
    hoverGradient: "linear-gradient(to right, rgba(86, 178, 47, 0.3), rgba(168, 224, 99, 0.3))",
    darkGradient: "linear-gradient(to right, rgba(76, 159, 56, 0.3), rgba(168, 224, 99, 0.3))",
  },
  Rising: {
    gradient: "linear-gradient(60deg, rgba(61, 51, 147, 0.3) 0%, rgba(43, 118, 185, 0.3) 37%, rgba(44, 172, 209, 0.3) 65%, rgba(53, 235, 147, 0.3) 100%)",
    hoverGradient: "linear-gradient(60deg, rgba(53, 235, 147, 0.3) 0%, rgba(44, 172, 209, 0.3) 37%, rgba(43, 118, 185, 0.3) 65%, rgba(61, 51, 147, 0.3) 100%)",
    darkGradient: "linear-gradient(60deg, rgba(43, 38, 110, 0.3) 0%, rgba(33, 90, 133, 0.3) 37%, rgba(31, 142, 183, 0.3) 65%, rgba(38, 191, 126, 0.3) 100%)",
  },
  Flaming: {
    gradient: "linear-gradient(-60deg, rgba(255, 88, 88, 0.3) 0%, rgba(240, 152, 25, 0.3) 100%)",
    hoverGradient: "linear-gradient(-60deg, rgba(240, 152, 25, 0.3) 0%, rgba(255, 88, 88, 0.3) 100%)",
    darkGradient: "linear-gradient(-60deg, rgba(212, 79, 79, 0.3) 0%, rgba(207, 114, 20, 0.3) 100%)",
  },
};

export const backgroundColors = {
  Ash: "#E8F0F2",
  Rising: "#E8F0F2",
  Flaming: "#FFF1E6",
};
