import React from 'react';
import { AwesomeButton } from 'react-awesome-button';
import { Container, Group } from '@mantine/core';
import 'react-awesome-button/dist/styles.css';
import { useTheme } from '../context/ThemeContext';
import { useNavigate } from 'react-router-dom';

// Define a theme configuration dictionary
const themeConfigs = {
 Ash: {
  gradient : 'linear-gradient(to right, #a8e063, #56ab2f)',
  hoverGradient : 'linear-gradient(to right, #56ab2f, #a8e063)',
  darkGradient : 'linear-gradient(to right, #4c9f38, #a8e063)',
 },
 Rising: {
   gradient: 'linear-gradient(60deg, #3d3393 0%, #2b76b9 37%, #2cacd1 65%, #35eb93 100%)',
   hoverGradient: 'linear-gradient(60deg, #35eb93 0%, #2cacd1 37%, #2b76b9 65%, #3d3393 100%)',
   darkGradient: 'linear-gradient(60deg, #2b266e 0%, #215a85 37%, #1f8eb7 65%, #26bf7e 100%)',
 },
 Flaming: {
   gradient: 'linear-gradient(-60deg, #ff5858 0%, #f09819 100%)',
   hoverGradient: 'linear-gradient(-60deg, #f09819 0%, #ff5858 100%)',
   darkGradient: 'linear-gradient(-60deg, #d44f4f 0%, #cf7214 100%)',
 }
};

const ModuleButtons = () => {
  const { theme } = useTheme();
  const config = themeConfigs[theme] || {}; // Use default if theme not found
  const navigate = useNavigate();

  const buttonStyle = {
    '--button-primary-color': config.gradient || 'gray', // Fallback to a default
    '--button-primary-gradient': config.gradient || 'gray', 
    '--button-primary-color-dark': config.darkGradient || 'darkgray', 
    '--button-primary-color-light': '#FFFFFF',
    '--button-primary-color-hover': config.hoverGradient || 'lightgray', 
    '--button-primary-color-active': config.hoverGradient || 'lightgray', 
  };

  return (
    <Container>
     <Group justify="center"
     align="center"
     direction="row"
     wrap="wrap">
      <AwesomeButton style={buttonStyle} onPress={() => navigate('/socialsphere')}>SocialSphere</AwesomeButton>
      <AwesomeButton style={buttonStyle} onPress={() => navigate('/hearthaven')}>HeartHaven</AwesomeButton>
      <AwesomeButton style={buttonStyle} onPress={() => navigate('/workwise')}>WorkWise</AwesomeButton>
      <AwesomeButton style={buttonStyle} onPress={() => navigate('/financialflourish')}>FinancialFlourish</AwesomeButton>
      </Group>
      </Container>
      );
};

export default ModuleButtons;
