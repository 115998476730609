import React from 'react';
import { Container, Grid, Title, Space, Paper, Flex } from '@mantine/core';
// import ModuleButtons from '../../components/ModuleButtons'; // Adjust the path as necessary
import LogoutButton from '../../components/LogoutButton'; // Adjust the path as necessary
import BackButton from '../../components/BackButton'; // Import the newly created BackButton
//import TableTopicsUploadForm from '../../components/forms/TableTopicsUploadForm';
import QuestionsCarousel from './components/QuestionsCarousel';

import { useTheme } from '../../context/ThemeContext'; // Adjust the path as necessary
import { themeConfigs, backgroundColors } from '../../utils/themeConfigurations'; // Adjust the path as necessary

const HeartHaven = () => {
  const { theme } = useTheme(); // Access the current theme
  const config = themeConfigs[theme] || themeConfigs.Rising; // Use default config if theme not found
  const currentBackgroundColor = backgroundColors[theme] || backgroundColors.Rising; // Use default background if theme not found

  return (
    <Container fluid style={{ padding: '30px', background: currentBackgroundColor }}>
      <Grid justify="center" align="flex-start">
      <Grid.Col span={1}>
        <Flex justify="center"
              gap="xs"
      align="center"
      direction="row"
      wrap="wrap">
          <BackButton /> {/* Place the BackButton in the UI */}
          <LogoutButton />
          </Flex>
        </Grid.Col>
        <Grid.Col span={8} offset={1}>
          <Title align="center" order={2} style={{ marginBottom: '20px' }}>
            HeartHaven: Strengthening Connections
          </Title>
        </Grid.Col>
      </Grid>
      {/*}
      
      <Paper
        shadow="md"
        radius="md"
        p="15px"
        style={{ marginBottom: '10px', background: config.gradient }}
      >
        <Title order={4}>Family Connections</Title>
        <Space h="md" />
        <h1>Upload Table Topics</h1>
      <TableTopicsUploadForm />

      </Paper>
  */}
      <Paper
        shadow="md"
        radius="md"
        p="10px"
        withBorder
        style={{ marginBottom: '20px', background: config.darkGradient }}
      >
        <Title order={4}>Table Topics</Title>
        <Space h="md" />
        <QuestionsCarousel />

      </Paper>
{/*
      <Paper
        shadow="md"
        radius="md"
        p="15px"
        withBorder
        style={{ background: config.hoverGradient }}
      >
        <Title order={4}>Explore More Modules</Title>
        <Space h="md" />
        <ModuleButtons />
      </Paper>
*/}
    </Container>
  );
};

export default HeartHaven;
