import React from "react";
import {
  Container,
  Grid,
  Title,
  Space,
  Paper,
  useMantineTheme,
} from "@mantine/core";
import StateSlider from "./StateSlider";
import RadarChartComponent from "./RadarChartComponent";
import ModuleButtons from "./ModuleButtons";
import { useTheme } from "../context/ThemeContext"; // Import the useTheme hook
import LogoutButton from "./LogoutButton"; // Adjust the path as necessary
import { themeConfigs, backgroundColors } from '../utils/themeConfigurations';
// import PersonForm from './forms/PersonForm';

// <PersonForm />


const Dashboard = () => {
  const { theme } = useTheme();
  // eslint-disable-next-line no-unused-vars
  const mantineTheme = useMantineTheme();
  const config = themeConfigs[theme] || themeConfigs.Rising;
  const currentBackgroundColor = backgroundColors[theme] || backgroundColors["Rising"];
  


  // The rest of your component remains the same since it's mostly presentational
  return (
    <Container
      fluid
      style={{ padding: "30px", background: currentBackgroundColor }}
    >
      <Grid justify="center" align="flex-start">
        <Grid.Col span={1}>
          <LogoutButton />
        </Grid.Col>
        <Grid.Col span={8} offset={1}>
          <Title align="center" order={2} style={{ marginBottom: "20px" }}>
            Zenix Dashboard
          </Title>
        </Grid.Col>
      </Grid>
      <Paper
        shadow="md"
        radius="md"
        p="15px"
        style={{ marginBottom: "10px", background: config.gradient }}
      >
        <Title order={4}>Your Energy State</Title>
        <Space h="md" />
        <StateSlider />
      </Paper>

     

      <Paper
        shadow="md"
        radius="md"
        p="15px"
        withBorder
        style={{ marginBottom: "20px", background: config.darkGradient }}
      >
        <Title order={4}>Life Balance</Title>
        <Space h="md" />
        <RadarChartComponent />
      </Paper>

      <Paper
        shadow="md"
        radius="md"
        p="15px"
        withBorder
        style={{ background: config.hoverGradient }}
      >
        <Title order={4}>Explore Zenix Modules</Title>
        <Space h="md" />
        <ModuleButtons />
       
      </Paper>
    </Container>
  );
};

export default Dashboard;
