import React, { createContext, useContext, useState, useEffect } from 'react';
import { MantineProvider } from '@mantine/core';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('Rising'); // Default theme

  useEffect(() => {
    const root = document.documentElement; // Access the root element (:root in CSS)
    
    // Define the CSS variables for gradient based on the theme
    switch (theme) {
      case 'Ash':
        root.style.setProperty('--button-primary-gradient', 'linear-gradient(to right, green, blue)');
        break;
      case 'Rising':
        root.style.setProperty('--button-primary-gradient', 'linear-gradient(to right, blue, orange)');
        break;
      case 'Flaming':
        root.style.setProperty('--button-primary-gradient', 'linear-gradient(to right, orange, red)');
        break;
      default:
        root.style.setProperty('--button-primary-gradient', 'linear-gradient(to right, green, blue)'); // Default to Ash
    }
  }, [theme]); // This effect runs whenever theme state changes

  const updateTheme = (newTheme) => setTheme(newTheme);

  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        {children}
      </MantineProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
