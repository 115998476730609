/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect } from 'react';
import { firebase } from '../firebase';  // Ensure this path correctly points to where your firebase instance is configured
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
  console.log("useAuth:", firebase);
  const [currentUser, setCurrentUser] = useState(null);
  const auth = getAuth();
  console.log("AuthProvider:", auth);
  console.log("UserAuth:", auth.currentUser);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User signed in:", user);
      } else {
        console.log("No user signed in.");
      }
      setCurrentUser(user);
    });

    // Log when the component mounts and when it unmounts
    console.log("AuthProvider mounted.");
    return () => {
      console.log("Cleaning up AuthProvider.");
      unsubscribe();
    };
  }, []);

  // Log when currentUser changes
  useEffect(() => {
    console.log("Current user state changed:", currentUser);
  }, [currentUser]);

  return (
    <AuthContext.Provider value={currentUser}>
      {children}
    </AuthContext.Provider>
  );
};
