// src/components/RadarChartComponent.js
import React from 'react';
import { RadarChart } from '@mantine/charts';
import { useMantineTheme } from '@mantine/core';


const RadarChartComponent = () => {
  const theme = useMantineTheme(); // Get a reference to the Mantine theme

  const data = [
    { label: 'SocialSphere', value: 70 },
    { label: 'HeartHaven', value: 100 },
    { label: 'WorkWise', value: 85 },
    { label: 'FinancialFlourish', value: 75 },
  ];

  return (
    <RadarChart
      h={300}
      data={data}
      dataKey="label"
      withPolarRadiusAxis
      withGridLines={true}  // Add grid lines
      gridLineStyle={{ opacity: 0.3 }} // Customize grid lines
      series={[
        { name: 'value', color: theme.colors[theme.primaryColor][5], opacity: 0.4 } // Theme-based color
      ]}
      labels={({ datum }) => `${datum.label}: ${datum.value}`}  // Add labels
    />
  );
};

export default RadarChartComponent;
