import React, { useEffect, useState } from 'react';
import { Stack, Title, Button, Flex, Loader } from '@mantine/core';
import { fetchQuestions, updateQuestion } from '../../../firestore/TableTopicsService';
import { Carousel } from '@mantine/carousel';
import { IconEyeCheck,IconHeart, IconX, IconHeartFilled } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';


function QuestionCard({ question, onSeen, onFavorite, onHide }) {
  const isFavorite = question.favorite === "FALSE" || question.favorite === false;

  return (
    <Stack  justify="space-around" hadow="md" p="xl" radius="md" style={{ background: 'white' }}>
      <Title ta="center" order={3}>{question.question}</Title>
      <Flex gap="md"
      p="xl"
      justify="center"
      align="center"
      direction="row"
      wrap="wrap">
      <Button variant="light" color="green"  onClick={() => onSeen(question.id)}><IconEyeCheck/></Button>
      <Button variant="light" color="red" onClick={() => onFavorite(question.id)}>
            {isFavorite ? <IconHeart size={18} /> : <IconHeartFilled size={18} />}
          </Button>
        <Button variant="light" color="gray"  onClick={() => onHide(question.id)}>
        <IconX  />
        </Button>
      </Flex>
    </Stack>
  );
}

function QuestionsCarousel() {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchQuestions().then(questions => {
      setQuestions(questions);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      notifications.show({
        color: 'red',
        title: 'Failed to load questions',
        message: error.message,
      });
    });
  }, []);

  const updateState = async (id, field, value) => {
    setLoading(true);
    try {
      await updateQuestion(id, { [field]: value });
      setQuestions(questions.filter(q => q.id !== id));
      notifications.show({
        color: 'green',
        title: `Question ${field} updated`,
        message: `Question has been successfully marked as ${field}`,
      });
    } catch (error) {
      notifications.show({
        color: 'red',
        title: 'Failed to update question',
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSeen = (id) => updateState(id, 'seen', true);
  const handleFavorite = async (id) => {
    // Find the current question based on id
    const currentQuestion = questions.find(q => q.id === id);
  
    // Normalize the current favorite status to a Boolean
    const isCurrentlyFavorite = currentQuestion.favorite === "TRUE" || currentQuestion.favorite === true;
  
    // Determine the new favorite status by inverting the current status
    const newFavoriteStatus = !isCurrentlyFavorite;
  
    try {
      // Update the favorite status in Firestore
      await updateQuestion(id, { favorite: newFavoriteStatus ? true : false });  // Ensure to use true or "FALSE"
  
      // Update the local state to reflect this change
      setQuestions(questions.map(question => {
        if (question.id === id) {
          return { ...question, favorite: newFavoriteStatus ? true : false };
        }
        return question;
      }));
  
      // Show a success notification
      notifications.show({
        color: 'green',
        title: 'Favorite Updated',
        message: `Question has been marked as ${newFavoriteStatus ? "favorite" : "not favorite"}.`,
      });
    } catch (error) {
      // Show an error notification if the update fails
      notifications.show({
        color: 'red',
        title: 'Error updating favorite',
        message: 'Could not update the favorite status. Please try again.'
      });
    }
  };
  
  const handleHide = (id) => updateState(id, 'hide', true);

  const slides = questions.map((question) => (
    <Carousel.Slide key={question.id}>
      <QuestionCard question={question} onSeen={handleSeen} onFavorite={handleFavorite} onHide={handleHide} />
    </Carousel.Slide>
    
  ));
  console.log("Slides:", slides);

  return (
    <div style={{ textAlign: "center", color: "black" }} >
      {loading ? <Loader /> : (
    <Carousel
      slideSize="100%"
      slideGap="md"
      controlsOffset="xl"
      loop
      >
        
      {slides}
    </Carousel>
    )}
    </div>
  );
}

export default QuestionsCarousel;
