import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCe68MPIr33QQOXEo9xVybVJ8565PuHQdE",
  authDomain: "zenix-web.firebaseapp.com",
  projectId: "zenix-web",
  storageBucket: "zenix-web.appspot.com",
  messagingSenderId: "229975686252",
  appId: "1:229975686252:web:d0db159e02b37d29d4ec49",
  measurementId: "G-1RYNMQZTZ5"
};

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, analytics, firebase, db };

