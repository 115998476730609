// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";
import { ThemeProvider } from "./context/ThemeContext"; // Import the ThemeProvider
import "firebaseui/dist/firebaseui.css";
import { AuthProvider } from "./context/AuthContext";
import { Notifications } from "@mantine/notifications";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <MantineProvider>
        <AuthProvider>
        <Notifications />
            <App />
        </AuthProvider>
      </MantineProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// Performance measurement, if needed
reportWebVitals();
